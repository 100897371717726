var __templateData = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"gallery-cell\"><div class=\"img-wrapper\"><img src=\"https://res.cloudinary.com/olafureliasson-net/image/upload/img/static/icewatch/shared/slideshow1/set1a_1.jpg\"/></div></div><div class=\"gallery-cell\"><div class=\"img-wrapper\"><img src=\"https://res.cloudinary.com/olafureliasson-net/image/upload/img/static/icewatch/shared/slideshow1/set1a_2.jpg\"/></div></div><div class=\"gallery-cell\"><div class=\"img-wrapper\"><img src=\"https://res.cloudinary.com/olafureliasson-net/image/upload/img/static/icewatch/shared/slideshow1/set1a_3.jpg\"/></div></div><div class=\"gallery-cell\"><div class=\"img-wrapper\"><img src=\"https://res.cloudinary.com/olafureliasson-net/image/upload/img/static/icewatch/shared/slideshow1/set1a_4.jpg\"/></div></div><div class=\"gallery-cell\"><div class=\"img-wrapper\"><img src=\"https://res.cloudinary.com/olafureliasson-net/image/upload/img/static/icewatch/shared/slideshow1/set1a_5.jpg\"/></div></div><div class=\"gallery-cell\"><div class=\"img-wrapper\"><img src=\"https://res.cloudinary.com/olafureliasson-net/image/upload/img/static/icewatch/shared/slideshow1/set1a_6.jpg\"/></div></div><div class=\"gallery-cell\"><div class=\"img-wrapper\"><img src=\"https://res.cloudinary.com/olafureliasson-net/image/upload/img/static/icewatch/shared/slideshow1/set1a_7.jpg\"/></div></div><div class=\"gallery-cell\"><div class=\"img-wrapper\"><img src=\"https://res.cloudinary.com/olafureliasson-net/image/upload/img/static/icewatch/shared/slideshow1/set1a_8.jpg\"/></div></div><div class=\"gallery-cell\"><div class=\"img-wrapper\"><img src=\"https://res.cloudinary.com/olafureliasson-net/image/upload/img/static/icewatch/shared/slideshow1/set1a_9.jpg\"/></div></div>");;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}