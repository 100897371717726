var __templateData = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"gallery-cell\"><video src=\"https://res.cloudinary.com/olafureliasson-net/video/upload/v1679481595/img/static/icewatch/london/intro.mp4\" poster=\"\" id=\"video_intro_1\" width=\"auto\" height=\"auto\" loop=\"loop\" data-setup=\"{}\" muted=\"muted\" class=\"video-js vjs-sublime-skin\"></video></div>");;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}