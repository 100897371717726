var __templateData = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"gallery-cell\"><div class=\"img-wrapper\"><img src=\"https://res.cloudinary.com/olafureliasson-net/image/upload/img/static/icewatch/shared/video/ICE_Watch_SlowMO_02.jpg\"/></div></div>");;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}