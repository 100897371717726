var __templateData = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"gallery-cell\"><video src=\"https://res.cloudinary.com/olafureliasson-net/video/upload/img/static/icewatch/london/ICE_Watch_SlowMO_0_1.mp4\" poster=\"https://res.cloudinary.com/olafureliasson-net/image/upload/img/static/icewatch/london/wholeearth.jpg\" id=\"video_3_1\" width=\"auto\" height=\"auto\" controls=\"controls\" data-setup=\"{&quot;autoplay&quot;: false}\" class=\"video-js vjs-sublime-skin\"></video></div><div class=\"gallery-cell\"><video src=\"https://res.cloudinary.com/olafureliasson-net/video/upload/img/static/icewatch/london/ICE_Watch_SlowMO_0_1.mp4\" poster=\"https://res.cloudinary.com/olafureliasson-net/image/upload/img/static/icewatch/london/wholeearth.jpg\" id=\"video_3_2\" width=\"auto\" height=\"auto\" controls=\"controls\" data-setup=\"{&quot;autoplay&quot;: false}\" class=\"video-js vjs-sublime-skin\"></video></div>");;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}